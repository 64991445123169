@font-face {
    font-family: 'NotoSans-Bold';
    src: local('NotoSans'), url(./assets/fonts/NotoSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSans-Italic';
    src: local('NotoSans'), url(./assets/fonts/NotoSans-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSans-Regular';
    src: local('NotoSans'), url(./assets/fonts/NotoSans-Regular.ttf) format('truetype');
}

body {
    font-family: 'NotoSans-Regular';
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
}

.logo {
    float: left;
    width: 220px;
    height: 50px;
    margin-top:8px;
    background: #fff url(./assets/imgs/logo.png) no-repeat;
    background-size: 300px 300px ;
    background-size: contain;
}

.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

[data-theme="dark"] .site-layout-content {
    background: #141414;
}

