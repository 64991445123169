body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  width: 100%;
}
.antd-table {
    border:1px solid #ccc;
    width:100%
}
.antd-table td, .antd-table tr {
    padding:3px;
    /* 3.Put a top border of 1px solid #6B6B6B after the table headers */
    border: 1px solid #CCC; }

.print-only {
    display:none;
}
@media print {
    .print-only {
        display:block;
    }
    @page{
        size: A4 !important;
        margin: 25px !important;
    }
    .no-print {
        display:none !important;
    }
    .ant-form-item {
        margin-bottom:2px !important;
    }
    .ant-card-body {
        padding:2px 24px !important;
    }
    .ant-col-8 {
        flex: 0 0 35% !important;
        margin:0 15px 0 0 !important;
        padding:0 !important;
        text-align:right !important;
    }
    .ant-col-12 {
        flex: 0 0 50% !important;
    }
    .ant-card-head-title {
        padding:0 !important;
    }
    .ant-card-head {
        min-height: 16px !important;
    }
    h1 {
        display:none !important;
    }

    .center {
        text-align:center;
    }
}
